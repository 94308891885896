import { FC } from 'react';
import styled from 'styled-components';
import Neutrogena from '../../assets/images/neutrogenaLogo.png';

const NeutrogenaLogoImg = styled.img`
  ${({ theme }) => `

  position: absolute;
  right: 0;
  left: 0;
  margin: auto;
  width: ${theme.getValueWidth(30)}px;
  `}
`;

const NeutrogenaLogo: FC = () => <NeutrogenaLogoImg src={Neutrogena} />;

export default NeutrogenaLogo;
