/* eslint-disable import/no-named-as-default */
import styled from 'styled-components';
import TextBox from '../components/TextBox';
import Disc from '../assets/images/musicDisk.png';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Cloud from '../assets/images/cloud.png';
import NeutrogenaLogo from '../components/NeutrogenaLogo';

const FIRST_ANIMATION_DELAY = 0.5;
const SINGLE_ANIMATION_DURATION = 0.5;
const BOTTOM_ANIMATION_DELAY = FIRST_ANIMATION_DELAY + SINGLE_ANIMATION_DURATION * 2;
const NEEDLE_COMPLETE_ANIMATION_TIME_MS = 1300;

const CLOUDS_ANIMTION_DELAY = 1;

const Container = styled.div`
  ${({ theme }) => `
width: ${theme.getValueWidth(100)}px;
height: ${theme.getValueHeight(100)}px;
display: flex;
align-items: center;
justify-content: flex-start;
flex-direction: column;
overflow: hidden;
position: relative;
background: rgb(143,205,213);
background: linear-gradient(180deg, rgba(143,205,213,1) 0%, rgba(243,250,251,1) 100%);
@keyframes slide-in {
  0% {
    opacity: 0;
    left: ${theme.getValueWidth(40)}px;
  }
  100% { 
    opacity: 1;
    left: 0; 
  }
}

`}
`;

const TextContainer = styled.div`
  ${({ theme }) => `
width: ${theme.getValueWidth(100)}px;
height: ${theme.getValueHeight(52.5)}px;
display: flex;
flex-direction: column;
position: relative;
`}
`;

const Title = styled.h1`
  ${({ theme }) => `
font-family: ${theme.fonts.blenny};
  color: ${theme.colors.white};
  font-size: ${theme.getValueWidth(17)}px;
  line-height: ${theme.getValueWidth(17)}px;
  width: ${theme.getValueWidth(70)}px;
  word-break: break-word;
  padding: ${theme.getValueHeight(11)}px ${theme.getValueWidth(15)}px ${theme.getValueHeight(2)}px;
  margin: 0;
  position: relative;
  animation: slide-in ${SINGLE_ANIMATION_DURATION}s forwards;
  animation-delay: ${FIRST_ANIMATION_DELAY}s;
  opacity: 0;
`}
`;

const Text = styled.p`
  ${({ theme }) => `
width: ${theme.getValueWidth(70)}px;
height: auto;
color: ${theme.colors.green};
font-family: ${theme.fonts.cadiz};
font-size: ${theme.getValueHeight(1.75)}px;
padding: 0 ${theme.getValueWidth(15)}px ${theme.getValueHeight(2)}px ${theme.getValueWidth(15)}px;
margin: 0;
@media (${theme.breakpoints.mobileL}) {
    font-size: ${theme.getValueWidth(4)}px;
  }
position: relative;
animation: slide-in ${SINGLE_ANIMATION_DURATION}s forwards;
animation-delay: ${FIRST_ANIMATION_DELAY + SINGLE_ANIMATION_DURATION}s;
opacity: 0;
`}
`;

const ImageContainer = styled.div`
  ${({ theme }) => `
width: ${theme.getValueWidth(100)}px;
height: ${theme.getValueHeight(48.5)}px;
display: flex;
flex-direction: column;
overflow: hidden;
`}
`;

const NeedleContainer = styled.div`
  ${({ theme }) => `
width: ${theme.getValueWidth(100)}px;
height: auto;
display: flex;
position: absolute;

@keyframes slide-in-needle {
  0% {
    opacity: 0;
    right: -${theme.getValueWidth(65)}px;
  }
  100% { 
    opacity:1;
    right: ${theme.getValueWidth(-15)}px; 
  }
}

.home-text {
  visibility: visible;
  transition: visibility 1s;
  transition-delay: 1s;
  transition-timing-function: ease-in-out;

}

&.isClicked {
    img {
      transform: rotate(31deg);
      top: 39px;
    }
    .home-text {
      opacity: 0;
      visibility: hidden;
    }
  }
`}
`;

const DiscContainer = styled.div`
  ${({ theme }) => `

  @keyframes slide-in-disc{
    from {opacity:0; bottom:-${theme.getValueHeight(48.5)}px;}
    to {opacity:1; bottom: 0;  }
  }

  width: ${theme.getValueWidth(100)}px;
  height: ${theme.getValueHeight(48.5)}px;
  display: flex;
  position: relative;
  overflow: hidden;
  animation:slide-in-disc ${SINGLE_ANIMATION_DURATION}s forwards ;
  animation-delay: ${BOTTOM_ANIMATION_DELAY}s;
  opacity:0;
`}
`;

const DiscHome = styled.img`
  ${({ theme }) => `
width: ${theme.getValueWidth(100)}px;
height: ${theme.getValueHeight(70)}px;
display: flex;
position: relative;
object-fit: cover;
top: ${theme.getValueHeight(12)}px;
`}
`;

const LeftCloud = styled.img`
  ${({ theme }) => `

@keyframes slide-in-left {
  0% {
    opacity: 0;
    left: ${theme.getValueWidth(-25)}px;
  }
  100% { 
    opacity:0.6;
    left: ${theme.getValueWidth(-18.8)}px;
  }
}

animation: slide-in-left ${CLOUDS_ANIMTION_DELAY}s forwards;
  animation-delay: ${CLOUDS_ANIMTION_DELAY}s;
  opacity: 0;
  position: absolute;
  left: 0px;
  width: ${theme.getValueWidth(41)}px;
  top: ${theme.getValueWidth(5.4)}px;
  left: ${theme.getValueWidth(-25)}px;

  `}
`;

const RightCloud = styled.img`
  ${({ theme }) => `

@keyframes slide-in-right {
  0% {
    opacity: 0;
    right: ${theme.getValueWidth(-25)}px;
  }
  100% { 
    opacity:0.6;
    right: ${theme.getValueWidth(-18)}px;
  }
}

  animation: slide-in-right ${CLOUDS_ANIMTION_DELAY}s forwards;
  animation-delay: ${CLOUDS_ANIMTION_DELAY}s;
  width: ${theme.getValueWidth(35)}px;
  opacity: 0;
  top: ${theme.getValueWidth(15)}px;

  position: absolute;
  right: ${theme.getValueWidth(-25)}px;
  `}
`;

function Home() {
  const navigate = useNavigate();
  const [isClicked, setIsClicked] = useState(false);
  const [isRendered, setIsRendered] = useState(false);

  useEffect(() => {
    setIsRendered(true);
  });

  const goToRounds = () => {
    setIsClicked(true);

    setTimeout(() => {
      navigate('/rounds');
      setIsClicked(false);
    }, NEEDLE_COMPLETE_ANIMATION_TIME_MS);
  };

  return (
    <Container>
      <NeutrogenaLogo />
      <LeftCloud src={Cloud} />
      <RightCloud src={Cloud} />
      <TextContainer>
        <Title>Prep for sunny vibes</Title>
        <Text>
          Are you ready for all the fun in the sun? Select the sunny vibe essentials for all your
          plans.
        </Text>
      </TextContainer>
      {isRendered && (
        <ImageContainer>
          <DiscContainer>
            <DiscHome src={Disc} />
          </DiscContainer>

          <NeedleContainer>
            <TextBox
              text="Tap to start"
              className={`${isClicked ? 'home-text hidding' : 'home-text'}`}
              goToRounds={goToRounds}
            />
          </NeedleContainer>
        </ImageContainer>
      )}
    </Container>
  );
}

export default Home;
