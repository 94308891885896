/* eslint-disable import/no-named-as-default */
import styled from 'styled-components';
import Disc from '../assets/images/musicDiskWhite.png';
import ProductsImage from '../assets/images/productsImage.png';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import NeutrogenaLogo from '../components/NeutrogenaLogo';

const FIRST_ANIMATION_DELAY = 0.5;
const SINGLE_ANIMATION_DURATION = 0.5;
const BOTTOM_ANIMATION_DELAY = FIRST_ANIMATION_DELAY + SINGLE_ANIMATION_DURATION * 2;

const Container = styled.div`
  ${({ theme }) => `
width: ${theme.getValueWidth(100)}px;
height: ${theme.getValueHeight(100)}px;
display: flex;
position: relative;
align-items: center;
justify-content: flex-start;
flex-direction: column;
overflow: hidden;
background: rgb(143,205,213);
background: linear-gradient(180deg, rgba(143,205,213,1) 0%, rgba(243,250,251,1) 100%);

@keyframes slide-in {
  0% {
    opacity: 0;
    left: ${theme.getValueWidth(40)}px;
  }
  100% { 
    opacity: 1;
    left: 0; 
  }
}

@keyframes appear {
  0% {
    opacity: 0;
    margin-top: ${theme.getValueHeight(5)}px;
  }
  100% { 
    opacity: 1;
    margin-top: ${theme.getValueHeight(0)}px;
  }
}
`}
`;

const TextContainer = styled.div`
  ${({ theme }) => `
width: ${theme.getValueWidth(100)}px;
height: ${theme.getValueHeight(45)}px;
margin-top: ${theme.getValueHeight(19)}px;
display: flex;
flex-direction: column;
z-index: 1;
`}
`;

const TitleBack = styled.h1`
  ${({ theme }) => `
font-family: ${theme.fonts.blenny};
  color: ${theme.colors.blue};
  font-size: ${theme.getValueWidth(15.75)}px;
  width: ${theme.getValueWidth(80)}px;
  line-height: ${theme.getValueHeight(10)}px;
  padding: ${theme.getValueHeight(4)}px ${theme.getValueWidth(12)}px ${theme.getValueHeight(2)}px;
  margin: 0;
  @media (${theme.breakpoints.mobileL}) {
    font-size: ${theme.getValueWidth(16)}px;
    max-width: ${theme.getValueWidth(85)}px;
  }
  height: ${theme.getValueHeight(30)}px;
  position: relative;
  
  animation: slide-in ${SINGLE_ANIMATION_DURATION}s forwards;
  animation-delay: ${FIRST_ANIMATION_DELAY}s;
  opacity: 0;
`}
`;

const Title = styled.h1`
  ${({ theme }) => `
font-family: ${theme.fonts.blenny};
  color: ${theme.colors.white};
  font-size: ${theme.getValueWidth(15.5)}px;
  width: ${theme.getValueWidth(80)}px;
  line-height: ${theme.getValueHeight(10)}px;
  padding: ${theme.getValueHeight(4)}px ${theme.getValueWidth(12)}px ${theme.getValueHeight(0)}px;
  margin-top: -${theme.getValueHeight(36.35)}px;
  margin-bottom: ${theme.getValueHeight(3)}px;
  height: ${theme.getValueHeight(20)}px;
  @media (${theme.breakpoints.mobileL}) {
    font-size: ${theme.getValueWidth(16)}px;
    margin-top: -${theme.getValueHeight(36.65)}px;
    max-width: ${theme.getValueWidth(85)}px;
  }
  position: relative;
  animation: slide-in ${SINGLE_ANIMATION_DURATION}s forwards;
  animation-delay: ${FIRST_ANIMATION_DELAY}s;
  opacity: 0;
`}
`;

const Text = styled.p`
  ${({ theme }) => `
width: ${theme.getValueWidth(75)}px;
height: auto;
color: ${theme.colors.green};
font-family: ${theme.fonts.cadiz};
font-size: ${theme.getValueHeight(2)}px;
padding: 0 ${theme.getValueWidth(15)}px ${theme.getValueHeight(3)}px ${theme.getValueWidth(14)}px;
margin: 0;
@media (${theme.breakpoints.mobileL}) {
    font-size: ${theme.getValueWidth(4)}px;
  }
position: relative;
animation: slide-in ${SINGLE_ANIMATION_DURATION}s forwards;
animation-delay: ${FIRST_ANIMATION_DELAY + SINGLE_ANIMATION_DURATION}s;
opacity: 0;
`}
`;

const DiscImg = styled.img`
  ${({ theme }) => `
  width: ${theme.getValueWidth(100)}px;
  height: ${theme.getValueHeight(90)}px;
  display: flex;
  position: absolute;
  object-fit: cover;
  z-index: 0;
  opacity: 0.25;
  top: ${theme.getValueHeight(4)}px;
`}
`;

const ProductsImg = styled.img`
  ${({ theme }) => `
  width: ${theme.getValueHeight(22)}px;
  height: ${theme.getValueHeight(22)}px;
  display: flex;
  position: absolute;
  object-fit: contain;
  z-index: 1;
  animation: appear ${SINGLE_ANIMATION_DURATION}s forwards;
  animation-delay: ${BOTTOM_ANIMATION_DELAY}s;
  opacity: 0;
  top: -${theme.getValueHeight(3)}px;
`}
`;

const SmallText = styled.p`
  ${({ theme }) => `
  position: absolute;
  z-index: 1;
  top: ${theme.getValueHeight(22)}px;
  width: ${theme.getValueWidth(50)}px;
  height: ${theme.getValueHeight(22)}px;
  color: ${theme.colors.green};
  font-family: ${theme.fonts.cadiz};
  font-size: ${theme.getValueHeight(1.37)}px;
  margin: 0;
  animation: appear ${SINGLE_ANIMATION_DURATION}s forwards;
  animation-delay: ${BOTTOM_ANIMATION_DELAY}s;
  opacity: 0;

  @media (${theme.breakpoints.mobileL}) {
    top: ${theme.getValueHeight(25)}px;
  }
`}
`;

const BottomContainer = styled.div`
  ${({ theme }) => `
  position: relative;
  z-index: 1;
  width: ${theme.getValueWidth(50)}px;
  height: ${theme.getValueHeight(27)}px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  animation: appear ${SINGLE_ANIMATION_DURATION}s forwards;
  animation-delay: ${BOTTOM_ANIMATION_DELAY}s;
  opacity: 0;
`}
`;

function Form() {
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      navigate('/');
    }, 20000);
  }, []);

  return (
    <Container>
      <NeutrogenaLogo />
      <DiscImg src={Disc} />
      <TextContainer>
        <TitleBack>Vibe Checked</TitleBack>
        <Title>Vibe Checked</Title>
        <Text>
          You did it! Make sure to stay prepared for all the sunny vibes ahead and ask a BA for your
          free fanny pack.
        </Text>
      </TextContainer>
      <BottomContainer>
        <ProductsImg src={ProductsImage} />
        <SmallText>
          And don't forget to check out all the other essentials to help you make the best of your
          sunny vibes! Scan QR code to visit store.
        </SmallText>
      </BottomContainer>
    </Container>
  );
}

export default Form;
