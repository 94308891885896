import { FC } from 'react';
import styled from 'styled-components';
import TapToStart from '../../assets/images/tapToStart.png';

type TextBoxType = {
  text: string;
  className?: string;
  goToRounds: () => void;
};

const FIRST_ANIMATION_DELAY = 2;
const SINGLE_ANIMATION_DURATION = 1;

const Container = styled.div`
  ${({ theme }) => `


@keyframes appear {
  0% {
    opacity: 0;
    margin-top: ${theme.getValueHeight(5)}px;
  }
  100% { 
    opacity: 1;
    margin-top: ${theme.getValueHeight(0)}px;
  }
}

@keyframes disappear {
  0% {
    opacity: 1;
    margin-top: ${theme.getValueHeight(0)}px;
  }
  100% { 
    opacity: 0;
    margin-top: ${theme.getValueHeight(5)}px;
  }
}


width: ${theme.getValueWidth(30)}px;
display: flex;
flex-direction: row;
position: relative;
top: ${theme.getValueHeight(4)}px;

margin: 0 auto;

animation: appear ${SINGLE_ANIMATION_DURATION}s forwards;
animation-delay: ${FIRST_ANIMATION_DELAY}s;
opacity:0;

&.hidding {

  animation: disappear ${SINGLE_ANIMATION_DURATION}s forwards;
  animation-delay: 0s;
}

img {
  width: 100%;
  opacity: 0.7;
}


`}
`;

const TextBox: FC<TextBoxType> = ({ className = '', goToRounds }: TextBoxType) => {
  return (
    <Container className={className} onClick={() => goToRounds()}>
      <img src={TapToStart} />
    </Container>
  );
};

export default TextBox;
