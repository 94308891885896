import * as React from 'react';
import { useIdleTimer } from 'react-idle-timer';

interface ContextProps {
  isIdle: boolean;
}

export const IdleContext = React.createContext({} as ContextProps);

interface Props {
  children: React.ReactNode;
}

export const IdleProvider: React.FC<Props> = ({ children }: Props) => {
  const [state, setState] = React.useState<string>('Active');
  const [count, setCount] = React.useState<number>(0);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [remaining, setRemaining] = React.useState<number>(0);

  const onIdle = () => {
    setState('Idle');
  };

  const onActive = () => {
    setState('Active');
  };

  const onAction = () => {
    setCount(count + 1);
  };

  const { getRemainingTime } = useIdleTimer({
    onIdle,
    onActive,
    onAction,
    timeout: 45_000,
    throttle: 500,
  });

  React.useEffect(() => {
    const interval = setInterval(() => {
      setRemaining(Math.ceil(getRemainingTime() / 1000));
    }, 500);

    return () => {
      clearInterval(interval);
    };
  });

  return (
    <IdleContext.Provider
      value={{
        isIdle: state === 'Idle',
      }}>
      {children}
    </IdleContext.Provider>
  );
};

export default function useIdleContext() {
  return React.useContext(IdleContext);
}
