import { useEffect, useState } from 'react';
import debounce from 'lodash/debounce';

export const useContainerDimensions = (): { height: number; width: number } => {
  const [containerDimensions, setContainerDimensions] = useState({
    width: 0,
    height: 0,
  });

  useEffect(() => {
    function handleResize() {
      const { innerWidth, innerHeight } = window;
      const maxWidth = innerHeight * 0.6;
      console.log(innerWidth, maxWidth);
      setContainerDimensions({
        width: innerWidth > maxWidth ? maxWidth : innerWidth,
        height: innerHeight,
      });
    }
    window.addEventListener('resize', debounce(handleResize, 300));
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return containerDimensions;
};
