/* eslint-disable import/no-named-as-default */
import { useCallback, useMemo } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import styled, { ThemeProvider } from 'styled-components';
import { useDeviceDetector } from './hooks/useDeviceDetector';
import { useContainerDimensions } from './hooks/useContainerDimensions';
import { Route as AppRoute } from './types/routes.d';
import { colors, ColorsType, fonts, FontsType, breakpoints, BreakpointsType } from './config/theme';
import { getHeight, getWidth } from './utils/ui';
import Home from './screens/Home';
import Rounds from './screens/Rounds';
import Form from './screens/Form';
import { IdleProvider } from './context/IdleContext';

const Container = styled.div`
  height: 100vh;
  width: 100vw;
  background-color: ${({ theme }) => theme.colors.white};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const AppWrapper = styled.div<{ height: number; width: number }>`
  height: ${({ height }) => `${height}px`};
  width: ${({ width }) => `${width}px`};
  background-color: white;
`;

type Theme = {
  getValueWidth: (value: number) => number;
  getValueHeight: (value: number) => number;
  breakpoints: BreakpointsType;
  colors: ColorsType;
  fonts: FontsType;
  isMobile: boolean;
};

function App() {
  const { isMobile } = useDeviceDetector();
  const containerDimensions = useContainerDimensions();

  const getValueWidth = useCallback(
    (value: number): number => {
      return getWidth({
        containerDimensions,
        value,
      });
    },
    [containerDimensions, isMobile],
  );

  const getValueHeight = useCallback(
    (value: number): number => {
      return getHeight({
        containerDimensions,
        value,
      });
    },
    [containerDimensions, isMobile],
  );

  const theme = useMemo(
    (): Theme => ({
      getValueWidth,
      getValueHeight,
      colors,
      fonts,
      breakpoints,
      isMobile,
    }),
    [getValueWidth, getValueHeight, isMobile],
  );

  const renderRouter = () => (
    <Routes>
      <Route path={AppRoute.Home} element={<Home />} />
      <Route path={AppRoute.Rounds} element={<Rounds />} />
      <Route path={AppRoute.Form} element={<Form />} />
    </Routes>
  );

  console.log(getValueWidth(10));

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Container>
          <AppWrapper width={getValueWidth(100)} height={getValueHeight(100)}>
            <IdleProvider>{renderRouter()}</IdleProvider>
          </AppWrapper>
        </Container>
      </Router>
    </ThemeProvider>
  );
}

export default App;
