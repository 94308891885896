export const getHeight = ({
  containerDimensions,
  value,
}: {
  containerDimensions: { width: number; height: number };
  value: number;
}): number => {
  return containerDimensions.height * (value / 100);
};

export const getWidth = ({
  containerDimensions,
  value,
}: {
  containerDimensions: { width: number; height: number };
  value: number;
}): number => {
  return containerDimensions.width * (value / 100);
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getHeight,
  getWidth,
};
