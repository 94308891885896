import styled from 'styled-components';
import { FC } from 'react';
import neutrogenaLogo from '../../assets/images/neutrogenaLogo.png';

type TextBoxType = {
  text?: string;
};

const HeaderContainer = styled.div`
  ${({ theme }) => `
width: ${theme.getValueWidth(100)}px;
height: ${theme.getValueHeight(5)}px;
display: flex;
align-items: center;
justify-content: center;
flex-direction: row;
gap: ${theme.getValueWidth(1)}px;

p {
  font-family: ${theme.fonts.cadiz};
  font-size: ${theme.getValueHeight(1.25)}px;
}

`}
`;

const NeutrogenaLogo = styled.img`
  ${({ theme }) => `
width: ${theme.getValueWidth(38)}px;
height: ${theme.getValueHeight(5)}px;
object-fit: contain;
display: flex;

`}
`;

const Header: FC<TextBoxType> = ({ text }: TextBoxType) => {
  return (
    <HeaderContainer>
      <p>{text}</p> <NeutrogenaLogo src={neutrogenaLogo} />
    </HeaderContainer>
  );
};

export default Header;
