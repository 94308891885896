/* eslint-disable import/no-named-as-default */
import styled from 'styled-components';
import { useState, useEffect } from 'react';
import Disc from '../assets/images/sun.png';
import Cloud1Img from '../assets/images/cloud1.png';
import Cloud2Img from '../assets/images/cloud2.png';
import Question1Img from '../assets/images/question1.png';
import Question2Img from '../assets/images/question2.png';
import Question3Img from '../assets/images/question3.png';
import Question4Img from '../assets/images/question4.png';
import { Option as StyledOption } from '../components/Option';
import { useNavigate } from 'react-router-dom';
import Header from '../components/Header';
import useIdleContext from '../context/IdleContext';

const FIRST_ANIMATION_DELAY = 1;
const TEXT_ANIMATION_DELAY = 0.6;
const TITLE_ANIMATION_DURATION = 0.15;
const SUBTITLE_ANIMATION_DURATION = 0.5;
const REPEAT_SONG_TITLE_ANIMATION_DURATION = 0.25;
const BOTTOM_ANIMATION_DURATION = 0.5;
const BOTTOM_ANIMATION_DELAY_FIRST_QUESTION = 2;
const BOTTOM_ANIMATION_DELAY = 1.25;
// questions
const QUESTION_ANIMATION_DURATION = 5000;
const CHANGE_QUESTION_TEXT_ANIMATION_DURATION = 3000;
const NEXT_QUESTION_TEXT_ANIMATION_DURATION = 3500;
const NEXT_QUESTION_CHANGE_DURATION = 4000;
const CLOUDS_DELAY_DURATION = 0;
const CLOUDS_ANIMATION_DURATION = 0.5;
const SUN_DELAY_DURATION = 0.5;
const SUN_ANIMATION_DURATION = 0.5;

const Container = styled.div`
  ${({ theme }) => `
position: relative;
width: ${theme.getValueWidth(100)}px;
height: ${theme.getValueHeight(100)}px;
display: flex;
align-items: center;
justify-content: center;
flex-direction: column;
background: #BDE4EB;
background: linear-gradient(180deg, #BDE4EB 0%, rgba(243,250,251,1) 100%);
z-index: 0;
overflow: hidden;

@keyframes slide-in {
  0% {
    opacity: 0;
    left: ${theme.getValueWidth(10)}px;
  }
  100% { 
    opacity: 1;
    left: 0;
  }
}

@keyframes slide-out {
  0% {
    opacity: 1;
    left: 0;
  }
  100% { 
    opacity: 0;
    left: ${theme.getValueWidth(10)}px;
  }
}

@keyframes appearBottom {
  0% {
    opacity: 0;
    margin-top: ${theme.getValueHeight(5)}px;
  }
  100% { 
    opacity: 1;
    margin-top: ${theme.getValueHeight(0)}px;
  }
}

@keyframes disappearBottom {
  0% {
    opacity: 1;
    margin-top: ${theme.getValueHeight(0)}px;
  }
  100% { 
    opacity: 0;
    margin-top: ${theme.getValueHeight(5)}px;
  }
}

`}
`;

const BackgroundContainer = styled.div`
  ${({ theme }) => `
display: flex;
width: ${theme.getValueWidth(100)}px;
height: ${theme.getValueHeight(100)}px;
position: absolute;
overflow: hidden;
z-index: 1;
`}
`;

const QuestionContainer = styled.div`
  ${({ theme }) => `
width: ${theme.getValueWidth(100)}px;
height: ${theme.getValueHeight(100)}px;
display: flex;
align-items: flex-start;
justify-content: flex-start;
flex-direction: column;
position: relative

`}
`;

const TitleContainer = styled.div`
  ${({ theme }) => `
display: flex;
flex-direction: row
width: ${theme.getValueWidth(60)}px;
position: relative;

margin: 0;
z-index: 2;
`}
`;

const Title = styled.h1<{ textLength: number; loaded: boolean }>`
  ${({ theme, loaded }) => `
    line-height: ${theme.getValueHeight(4.75)}px;
    font-family: ${theme.fonts.cadiz};
    font-size: ${theme.getValueHeight(3.75)}px;
    font-weight: 700;
    color: ${theme.colors.blue2};
    width: ${theme.getValueWidth(86)}px;
    padding: ${theme.getValueHeight(2)}px ${theme.getValueWidth(7)}px ${theme.getValueHeight(1)}px;
    margin: 0;
    z-index: 3;
    display: flex;
    align-items: flex-end;
    position: relative;
    opacity: 0;
    
    &.appear {
      animation: slide-in ${TITLE_ANIMATION_DURATION}s forwards;
      animation-delay: ${
        loaded ? TEXT_ANIMATION_DELAY : FIRST_ANIMATION_DELAY + SUBTITLE_ANIMATION_DURATION
      }s;
    }
    &.disappear {
      animation: slide-out ${TITLE_ANIMATION_DURATION}s forwards;
    }


    @media (${theme.breakpoints.mobileL}) {
      font-size: ${theme.getValueHeight(4.75)}px;
    }
`}
`;

const SubtitleContainer = styled.div`
  ${({ theme }) => `
display: flex;
flex-direction: row
width: ${theme.getValueWidth(60)}px;
margin: 0;
z-index: 2;
position: relative;
`}
`;

const Subtitle = styled.h2<{ loaded: boolean }>`
  ${({ theme, loaded }) => `
font-family: ${theme.fonts.blenny};
color: ${theme.colors.orange};
font-size: ${theme.getValueWidth(8)}px;
width: ${theme.getValueWidth(43)}px;
padding:  ${theme.getValueHeight(16.5)}px 0 0 ${theme.getValueWidth(7)}px;
margin: 0;
position: relative;
opacity: 0;
&.appear {
  animation: slide-in ${SUBTITLE_ANIMATION_DURATION}s forwards;
  animation-delay: ${loaded ? TEXT_ANIMATION_DELAY : FIRST_ANIMATION_DELAY}s;
}
&.disappear {
  animation: slide-out ${SUBTITLE_ANIMATION_DURATION}s forwards;
}
`}
`;

const DiscRound = styled.img`
  ${({ theme }) => `
  @keyframes appear-sun{
      0% {
        top: -${theme.getValueHeight(100)}px;
      }
      100% { 
        top: -${theme.getValueHeight(52.5)}px;
    }
  }

  @keyframes disappear-sun{
    0% {
      opacity: 1;
      top: -${theme.getValueHeight(52.5)}px;
    }
    100% { 
      opacity: 0;
      top: -${theme.getValueHeight(100)}px;
  }
}

  position: absolute;
  top: -${theme.getValueHeight(100)}px;
  width: ${theme.getValueHeight(80)}px;
  height: ${theme.getValueHeight(80)}px;
  display: flex;
  object-fit: contain;
  animation: appear-sun ${SUN_ANIMATION_DURATION}s forwards;
  animation-delay: ${SUN_DELAY_DURATION}s;

  &.disappear {
    animation: disappear-sun ${SUN_ANIMATION_DURATION}s forwards;
    animation-delay: ${NEXT_QUESTION_CHANGE_DURATION}ms;
    top: -${theme.getValueHeight(52.5)}px;
    opacity: 1;
  }
`}
`;

const SuccessImage = styled.img<{ imagesCount: number }>`
  ${({ theme, imagesCount }) => `

  @keyframes success-slide-in{
    0% {
      right: -${theme.getValueHeight((imagesCount > 3 ? 12 : 14) * imagesCount)}px;
    }
    100% { 
      right: ${theme.getValueWidth(5)}px;
    }
  }

  @keyframes success-slide-out{
    0% {
      right: ${theme.getValueWidth(5)}px;
    }
    100% { 
      right: -${theme.getValueHeight((imagesCount > 3 ? 12 : 14) * imagesCount)}px;
    }
  }

  position: absolute;
  top: ${theme.getValueHeight(50)}px;
  right: -${theme.getValueHeight((imagesCount > 3 ? 12 : 14) * imagesCount)}px;
  width: ${theme.getValueHeight((imagesCount > 3 ? 13.2 : 14) * imagesCount)}px;
  height: ${theme.getValueHeight(15)}px;
  display: flex;
  object-fit: contain;

  &.appear {
    animation: success-slide-in ${SUBTITLE_ANIMATION_DURATION}s forwards;
    animation-delay: 0.5s;
  }

  &.disappear {
    animation: success-slide-out ${SUBTITLE_ANIMATION_DURATION}s forwards;
  }
  `}
`;

const Cloud1 = styled.img`
  ${({ theme }) => `
  @keyframes cloud1-slide-in{
      0% {
        right: ${theme.getValueWidth(-50)}px;
      }
      100% { 
        right: ${theme.getValueWidth(-12)}px;
    }
  }

  position: absolute;
  bottom: ${theme.getValueWidth(0)}px;
  right: ${theme.getValueWidth(-50)}px;
  width: ${theme.getValueWidth(45)}px;
  height: ${theme.getValueHeight(10)}px;
  display: flex;
  object-fit: contain;
  animation: cloud1-slide-in ${CLOUDS_ANIMATION_DURATION}s forwards;
  animation-delay: ${CLOUDS_DELAY_DURATION}s;
`}
`;

const Cloud2 = styled.img`
  ${({ theme }) => `
  @keyframes cloud2-slide-in{
      0% {
        left: ${theme.getValueWidth(-50)}px;
      }
      100% { 
        left: ${theme.getValueWidth(0)}px;
    }
  }
  position: absolute;
  top: ${theme.getValueHeight(14)}px;
  left: ${theme.getValueWidth(-50)}px;
  width: ${theme.getValueWidth(40)}px;
  height: ${theme.getValueHeight(18)}px;
  display: flex;
  object-fit: contain;
  animation: cloud2-slide-in ${CLOUDS_ANIMATION_DURATION}s forwards;
  animation-delay: ${CLOUDS_DELAY_DURATION}s;
`}
`;

const AnswerContainer = styled.div<{ isFirst: boolean }>`
  ${({ theme, isFirst }) => `
width: ${theme.getValueWidth(100)}px;
height: ${theme.getValueHeight(22)}px;
display: flex;
flex-direction: row;
justify-content: center;
flex-wrap: wrap;
position: absolute;
bottom: ${theme.getValueHeight(10)}px;
z-index: 3;
gap: ${theme.getValueWidth(2)}px;
opacity: 0;
&.appear {
  animation: appearBottom ${BOTTOM_ANIMATION_DURATION}s forwards;
  animation-delay: ${isFirst ? BOTTOM_ANIMATION_DELAY_FIRST_QUESTION : BOTTOM_ANIMATION_DELAY}s;
}
&.disappear {
  animation: disappearBottom ${BOTTOM_ANIMATION_DELAY_FIRST_QUESTION}s forwards;
}
`}
`;

const roundsData = [
  {
    messages: {
      round: 'Question 1',
      default: 'The forecast is blue skies and a trip to the beach. What do you pack?',
      correct: "That's right! Always lotion up with Neutrogena®. Your skin will thank you later.",
      error: 'Nice try, but is that really "essential"?',
    },
    image: {
      src: Question1Img,
      imagesCount: 2,
    },
    options: [
      {
        label: 'Your fave swimsuit',
        status: false,
      },
      {
        label: 'A steamy beach read',
        status: false,
      },
      {
        label: 'A pineapple floaty',
        status: false,
      },
      {
        label: 'Sun protection',
        status: true,
      },
    ],
  },
  {
    messages: {
      round: 'Question 2',
      default: "You're running from class to a coffee date. What's the most essential?",
      correct: 'A+! Stay prepared with LISTERINE® COOL MINT® POCKETPAKS®.',
      error: "Eh, you're a good student but that's the wrong answer.",
    },
    image: {
      src: Question2Img,
      imagesCount: 1,
    },
    options: [
      {
        label: 'The class textbook',
        status: false,
      },
      {
        label: 'Your laptop',
        status: false,
      },
      {
        label: 'A neck pillow',
        status: false,
      },
      {
        label: 'Minty fresh breath',
        status: true,
      },
    ],
  },
  {
    messages: {
      round: 'Question 3',
      default: "The weather is warming up and you're feeling parched. What should you do?",
      correct: "You're heating up! Check out the eye & skin hydration essentials.",
      error: "You're not wrong, but you're also not right.",
    },
    image: {
      src: Question3Img,
      imagesCount: 4,
    },
    options: [
      {
        label: 'Order iced coffee',
        status: false,
      },
      {
        label: 'Take a cold shower',
        status: false,
      },
      {
        label: 'Get some ice cream',
        status: false,
      },
      {
        label: 'Hydrate your skin',
        status: true,
      },
    ],
  },
  {
    messages: {
      round: 'Question 4',
      default:
        "You're dancing the night away and showing off your sweet dance moves. What's your secret?",
      correct: 'Slay! Shop other first-aid essentials.',
      error: 'Uhh sorry, try again…',
    },
    image: {
      src: Question4Img,
      imagesCount: 3,
    },
    options: [
      {
        label: "You dance like no one's watching",
        status: false,
      },
      {
        label: 'Dance classes on the internet',
        status: false,
      },
      {
        label: "You're actually a professional dancer",
        status: false,
      },
      {
        label: 'The right<br />BAND-AID® for your heels',
        status: true,
      },
    ],
  },
];

type QuestionType = {
  messages: {
    round: string;
    default: string;
    correct: string;
    error: string;
  };
  options: Option[];
};

type Option = {
  label: string;
  status: boolean;
};

function Rounds() {
  const [questionIndex, setQuestionIndex] = useState(0);
  const [currentQuestion, setCurrentQuestion] = useState<QuestionType>(roundsData[questionIndex]);
  const [selectedOption, setSelectedOption] = useState<Option>();
  const [btnSelectedOption, setBtnSelectedOption] = useState<Option>();

  const [isTitleIn, setIsTitleIn] = useState(true);
  const [isSubtitleIn, setIsSubtitleIn] = useState(true);
  const [isSuccessImageIn, setIsSuccessImageIn] = useState(true);
  const [contentLoaded, setContentLoaded] = useState(false);

  const { isIdle } = useIdleContext();

  const navigate = useNavigate();

  const goToForm = () => {
    navigate('/form');
  };

  const fadeTitle = (time: number) => {
    setIsTitleIn(false);
    setTimeout(() => {
      setIsTitleIn(true);
    }, time);
  };

  const fadeSubtitle = (time: number) => {
    setIsSubtitleIn(false);
    setTimeout(() => {
      setIsSubtitleIn(true);
    }, time);
  };

  const fadeSuccesImage = (time: number) => {
    setIsSuccessImageIn(true);
    setTimeout(() => {
      setIsSuccessImageIn(false);
    }, time);
  };

  const nextRound = () => {
    const isLastQuestion = questionIndex + 1 > roundsData.length - 1;
    const fadeOutAnimation = isLastQuestion
      ? QUESTION_ANIMATION_DURATION - 1000
      : QUESTION_ANIMATION_DURATION;
    setTimeout(() => {
      fadeSubtitle(
        REPEAT_SONG_TITLE_ANIMATION_DURATION *
          (isLastQuestion
            ? CHANGE_QUESTION_TEXT_ANIMATION_DURATION
            : CHANGE_QUESTION_TEXT_ANIMATION_DURATION / 3),
      );
      fadeTitle(
        REPEAT_SONG_TITLE_ANIMATION_DURATION *
          (isLastQuestion
            ? CHANGE_QUESTION_TEXT_ANIMATION_DURATION
            : CHANGE_QUESTION_TEXT_ANIMATION_DURATION / 3),
      );
    }, fadeOutAnimation);
    setTimeout(() => {
      setSelectedOption(undefined);
      if (questionIndex + 1 > roundsData.length - 1) {
        goToForm();
      } else {
        setQuestionIndex(questionIndex + 1);
      }
    }, QUESTION_ANIMATION_DURATION);
    // fadeSubtitle(REPEAT_SONG_TITLE_ANIMATION_DURATION * 1000);
    fadeTitle(REPEAT_SONG_TITLE_ANIMATION_DURATION * 1000);
  };

  const selectAnswer = (option: Option) => {
    const isLastQuestion = questionIndex + 1 > roundsData.length - 1;
    setBtnSelectedOption(option);
    setTimeout(() => {
      setSelectedOption(option);
    }, REPEAT_SONG_TITLE_ANIMATION_DURATION * 1000);
    if (option.status) {
      nextRound();
      fadeSuccesImage(
        isLastQuestion ? QUESTION_ANIMATION_DURATION - 1000 : QUESTION_ANIMATION_DURATION,
      );
    } else {
      fadeTitle(REPEAT_SONG_TITLE_ANIMATION_DURATION * 1000);
      setTimeout(() => {
        fadeTitle(REPEAT_SONG_TITLE_ANIMATION_DURATION * 1000);
      }, NEXT_QUESTION_TEXT_ANIMATION_DURATION);
      setTimeout(() => {
        setSelectedOption(undefined);
      }, NEXT_QUESTION_CHANGE_DURATION);
    }
  };

  const shuffleArray = (array: Option[]) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  };

  useEffect(() => {
    if (questionIndex < roundsData.length) {
      setTimeout(() => {
        setCurrentQuestion({
          ...roundsData[questionIndex],
          options: shuffleArray(roundsData[questionIndex].options),
        });
      }, REPEAT_SONG_TITLE_ANIMATION_DURATION * 1000);
    }
  }, [questionIndex]);

  useEffect(() => {
    setTimeout(() => {
      setContentLoaded(true);
    }, (FIRST_ANIMATION_DELAY + SUBTITLE_ANIMATION_DURATION) * 1100);
  }, []);

  useEffect(() => {
    if (isIdle) {
      navigate('/');
    }
  }, [isIdle]);

  useEffect(() => {
    setBtnSelectedOption(selectedOption);
  }, [selectedOption]);

  return (
    <Container>
      <BackgroundContainer></BackgroundContainer>
      <Cloud1 src={Cloud1Img} />
      <Cloud2 src={Cloud2Img} />
      <DiscRound
        className={
          questionIndex + 1 > roundsData.length - 1 && btnSelectedOption?.status ? 'disappear' : ''
        }
        src={Disc}
      />
      {currentQuestion && (
        <QuestionContainer>
          <Header />
          <SubtitleContainer>
            <Subtitle loaded={contentLoaded} className={isSubtitleIn ? 'appear' : 'disappear'}>
              {currentQuestion.messages.round}
            </Subtitle>
          </SubtitleContainer>
          <TitleContainer>
            <Title
              loaded={contentLoaded}
              className={isTitleIn ? 'appear' : 'disappear'}
              textLength={
                (selectedOption
                  ? selectedOption.status
                    ? roundsData[questionIndex].messages.correct
                    : roundsData[questionIndex].messages.error
                  : roundsData[questionIndex].messages.default
                ).length
              }
              style={selectedOption && !selectedOption?.status ? { color: '#dd5e80' } : {}}>
              {selectedOption
                ? selectedOption.status
                  ? roundsData[questionIndex].messages.correct
                  : roundsData[questionIndex].messages.error
                : roundsData[questionIndex].messages.default}
            </Title>
          </TitleContainer>
          {selectedOption?.status && (
            <SuccessImage
              className={isSuccessImageIn ? 'appear' : 'disappear'}
              src={roundsData[questionIndex].image.src}
              imagesCount={roundsData[questionIndex].image.imagesCount}
            />
          )}
          <AnswerContainer
            isFirst={questionIndex === 0}
            className={isSubtitleIn ? 'appear' : 'disappear'}>
            {currentQuestion.options.map((option) => (
              <StyledOption
                option={option}
                selected={btnSelectedOption?.label || ''}
                onClick={() => !btnSelectedOption && selectAnswer(option)}
              />
            ))}
          </AnswerContainer>
        </QuestionContainer>
      )}
    </Container>
  );
}

export default Rounds;
